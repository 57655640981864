/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("vehicles/AddNewVehicleWidth", item)
        .then(response => {
          commit("ADD_VehicleWidth", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/vehicles/GetAllVehiclesWidths")
        .then(response => {
          if (response.status == 200) {
            commit("SET_VehicleWidth", response.data);
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchVehicleWidthByID({ commit }) {
    var Jsonvalue = {};
    Jsonvalue.CountryCode = "All";
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/GetVehiclesWidthById", Jsonvalue)
        .then(response => {
          if (response.status == 200) {
            commit("SET_VehicleWidth", response.data);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .put("/vehicles/UpdateVehicleWidth", item)
        .then(response => {
          debugger
          commit("UPDATE_VehicleWidth", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DeleteVehicleWidth({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/DeleteVehicleWidth?Id="+ item.ID)
        .then(response => {
          debugger
          commit("REMOVE_ITEM", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  UploadImage(context, image) {
    const formData = new FormData();
    // var jsonObject = image.params.Id;
    formData.append("file",image, image.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/UploadImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
