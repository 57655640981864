/*=========================================================================================
  File Name: moduleVehicleWidth.js
  Description: VehicleWidth Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleVehicleWidthState.js";
import mutations from "./moduleVehicleWidthMutations.js";
import actions from "./moduleVehicleWidthActions.js";
import getters from "./moduleVehicleWidthGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
